import { mapGetters, mapActions } from 'vuex'
import {
  CART_VALIDATION_ERRORS,
  PAGES,
  TRACKING_LISTS,
  PROMO_CODE_STORAGE_KEY,
  PROMOTION_VALIDATION_ERRORS
} from '~/utils/constants'

export default {
  data() {
    return {
      isRequestingUpgrade: false,
      paymentSuccessful: false,
      paymentValidationError: ''
    }
  },
  computed: {
    ...mapGetters({
      loggedInUser: 'loggedInUser',
      cart: 'subscriptions/cart',
      orderNumber: 'payment/orderNumber',
      loadingPaymentSession: 'payment/isFetchingPaymentSession'
    }),
    orderSummary() {
      if (!this.cart?.items?.length) return null

      const product = this.cart.items[0]

      const { productInfo, priceInfo, durationInfo } = product

      if (!productInfo) return null

      return {
        ...product,
        ...productInfo,
        ...priceInfo,
        ...durationInfo
      }
    }
  },
  methods: {
    ...mapActions({
      requestUpgrade: 'subscriptions/requestUpgrade',
      getCart: 'subscriptions/getCart',
      addToCart: 'subscriptions/addToCart',
      getPaymentSession: 'payment/getPaymentSession',
      clearPaymentSession: 'payment/clearPaymentSession',
      clearCart: 'subscriptions/clearCart',
      setPromotionValidity: 'promotion/setPromotionValidity'
    }),
    async upgradeToPremium() {
      try {
        this.isRequestingUpgrade = true
        await this.requestUpgrade()

        this.$router.push(`/${PAGES.subscriptionChangeOverview}`)
      } catch (error) {
        this.handleCartError(error)
      } finally {
        this.isRequestingUpgrade = false
      }
    },
    async getAndUpdateCart() {
      const productId = this.$route.query.id

      await this.getCart()

      if (productId) {
        await this.addToCart({
          productUid: this.$crypt.decrypt(productId)
        })

        this.$router.push({ query: null })
      }

      if (this.isPromo) {
        this.promoCode = this.getPromoCode()
        await this.validatePromoCode(this.promoCode)
        await this.addPromotionToCart()
      }
    },
    async processPayment(cartId) {
      if (this.loadingPaymentSession) return

      const { protocol, host } = window.location
      const { path } = this.$route
      const query = this.failedAutoRenewal
        ? `?autoRenewal=${this.failedAutoRenewal}`
        : ''

      await this.getPaymentSession({
        cartId,
        type: 'subscription',
        origin: `${protocol}//${host}`,
        returnUrl: `${protocol}//${host}${path}${query}`
      })

      this.applyStep2Tracking(this.promoCode)
    },
    onSuccess(isPending, queryOrderNumber) {
      this.paymentSuccessful = true
      const redirectUrl = {
        path: PAGES.subscriptionPurchaseSuccess,
        query: {}
      }

      const orderNumber = queryOrderNumber || this.orderNumber

      redirectUrl.query.payload = this.$crypt.encrypt(orderNumber)

      if (isPending) {
        redirectUrl.query.status = 'pending'
      }

      this.$router.push(redirectUrl)
      this.clearPaymentSession()
      this.clearCart()

      this.$localStorage.removeItem(PROMO_CODE_STORAGE_KEY)
    },
    applyStep2Tracking(promoCode) {
      try {
        const product = this.orderSummary
        const listInfo = promoCode
          ? TRACKING_LISTS.promo
          : TRACKING_LISTS.defaultSub

        const products = [
          {
            ...product,
            ...listInfo,
            quantity: 1,
            id: product.id || product.uid,
            coupon: promoCode,
            discount: promoCode
              ? product.originalPrice - product.finalPrice
              : 0,
            price: product.price || product.finalPrice
          }
        ]

        this.$gtmTracking.checkout({
          products,
          step: 2,
          totalPrice: product.finalPrice,
          coupon: promoCode
        })
        // eslint-disable-next-line no-empty
      } catch {}
    },
    handleCartError(error) {
      const errorLabel = this.$error.getLabel(error)
      const isCartValidationError = CART_VALIDATION_ERRORS.includes(errorLabel)
      const errorMessage = isCartValidationError
        ? errorLabel
        : 'messages.errorOccuredMessage'

      this.$toast.error(this.$label(errorMessage))
      this.$sentryCaptureException(error)
    },
    handleInitPaymentError(error) {
      const errorLabel = this.$error.getLabel(error)

      const isPromotionValidationError =
        PROMOTION_VALIDATION_ERRORS.includes(errorLabel)
      const isCartValidationError = CART_VALIDATION_ERRORS.includes(errorLabel)

      if (isPromotionValidationError || isCartValidationError) {
        this.setPromotionValidity(false)
        this.paymentValidationError = errorLabel
      } else {
        this.$sentryCaptureException(error)
        this.$toast.error(this.$label('messages.errorOccuredMessage'))
      }
    }
  }
}
